import { fetchWrapper } from "@/helpers";

const ruta = process.env.VUE_APP_ROOT_API;

export default class GrupoService {
  async getGrupoAll() {
    const grupos = await fetchWrapper.get(`${ruta}/grupos`);
    return grupos;
  }

  async sendGrupo(datos) {
    return await fetchWrapper.post(`${ruta}/grupos`, datos);
  }

  async deleteGrupo(id){
    return await fetchWrapper.delete(`${ruta}/grupos/${id}`);
  }

  async updateGrupo(grupo) {
    const actualizado = await fetchWrapper.put(
      `${ruta}/grupos/` + grupo.id,
      grupo
    );
    return actualizado;
  }
}